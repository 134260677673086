import React, { useState, useEffect } from 'react';
import Home from './components/Home';
import ExerciseChoice from './components/ExerciseChoice';
import Exercise from './components/Exercise';
import SplashScreen from './components/SplashScreen';
import { getRandomExercises } from './data/exercises';

const TOTAL_EXERCISES = 3;

const App = () => {
  const [step, setStep] = useState('splash');
  const [category, setCategory] = useState(null);
  const [difficulty, setDifficulty] = useState(null);
  const [currentExercise, setCurrentExercise] = useState(1);
  const [showSplash, setShowSplash] = useState(true);
  const [rerollTrigger, setRerollTrigger] = useState(0);

  // Vérifier si le splash screen a déjà été vu
  useEffect(() => {
    const hasSeenSplash = localStorage.getItem('hasSeenSplash');
    if (hasSeenSplash) {
      setShowSplash(false);
      setStep('home');
    }
  }, []);

  const handleCloseSplash = () => {
    localStorage.setItem('hasSeenSplash', 'true');
    setShowSplash(false);
    setStep('home');
  };

  const exercises = React.useMemo(() => {
    if (!difficulty || !category) return [];
    return getRandomExercises(category, difficulty);
  }, [difficulty, category, rerollTrigger]);

  const handleCategorySelect = (cat) => {
    setCategory(cat);
  };

  const handleDifficultySelect = (diff) => {
    setDifficulty(diff);
    setStep('choice');
  };

  const handleStart = () => {
    setCurrentExercise(1);
    setStep('exercise');
  };

  const handleBack = () => {
    setStep('choice');
    setCurrentExercise(1);
  };

  const handleReroll = () => {
    setRerollTrigger(prev => prev + 1);
  };

  const handleExerciseComplete = () => {
    if (currentExercise >= TOTAL_EXERCISES) {
      setStep('choice');
      setCurrentExercise(1);
    } else {
      setCurrentExercise(currentExercise + 1);
    }
  };

  return (
    <div>
      {showSplash && <SplashScreen onClose={handleCloseSplash} />}
      
      {!showSplash && (
        <>
          {step === 'home' && (
            <Home
              onSelectCategory={handleCategorySelect}
              onSelectDifficulty={handleDifficultySelect}
            />
          )}
          
          {step === 'choice' && (
            <ExerciseChoice
              exercises={exercises}
              onStart={handleStart}
              onReroll={handleReroll}
              onBack={() => setStep('home')}
            />
          )}
          
          {step === 'exercise' && exercises.length === TOTAL_EXERCISES && (
            <Exercise
              exercises={exercises}
              currentExercise={currentExercise}
              totalExercises={TOTAL_EXERCISES}
              onComplete={handleExerciseComplete}
              onBack={handleBack}
            />
          )}
        </>
      )}
    </div>
  );
};

export default App;