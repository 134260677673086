import React, { useState } from 'react';

const DifficultyButtons = ({ category, selectedCategory, selectedDifficulty, onSelection }) => {
  const difficultyLevels = [
    { level: 1, label: 'Débutant' },
    { level: 2, label: 'Intermédiaire' },
    { level: 3, label: 'Avancé' }
  ];

  return (
    <div className="grid grid-cols-3 gap-2">
      {difficultyLevels.map(({ level, label }) => (
        <button
          key={level}
          onClick={() => onSelection(category, level)}
          className={`border rounded p-2 w-full flex flex-col items-center ${
            selectedCategory === category && selectedDifficulty === level
              ? 'border-blue-500 bg-blue-50'
              : 'border-gray-200'
          }`}
        >
          <div className="flex mb-1">
            {'⭐'.repeat(level)}
          </div>
          <div className="text-xs text-gray-600">{label}</div>
        </button>
      ))}
    </div>
  );
};

const Home = ({ onSelectCategory, onSelectDifficulty }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDifficulty, setSelectedDifficulty] = useState(null);

  const handleSelection = (category, difficulty) => {
    setSelectedCategory(category);
    setSelectedDifficulty(difficulty);
  };

  const handleStart = () => {
    if (selectedCategory && selectedDifficulty) {
      onSelectCategory(selectedCategory);
      onSelectDifficulty(selectedDifficulty);
    }
  };

  return (
    <div className="h-screen flex flex-col">
      {/* Partie haute fixe */}
      <div className="flex-none">
        <div className="p-4">
          <div className="relative mx-auto max-w-sm">
            <img 
              src="/images/background.jpg" 
              alt="background" 
              className="w-full h-[250px] object-cover rounded-lg"
            />
            <div className="absolute inset-0 bg-black/30 rounded-lg" />
            
            <div className="absolute inset-0 flex flex-col items-center justify-center p-4">
              <h1 className="text-2xl font-bold text-white text-center mb-3">
                5 minutes chaque jour
              </h1>
              <p className="text-white text-center text-sm max-w-md">
                5 minutes de sport par jour suffisent à booster l'énergie, réduire le stress et améliorer la santé.
              </p>
            </div>
          </div>

          <div className="mt-2">
            <button 
              onClick={handleStart}
              disabled={!selectedCategory || !selectedDifficulty}
              className={`w-full py-2 rounded ${
                selectedCategory && selectedDifficulty 
                  ? 'bg-blue-600 text-white' 
                  : 'bg-gray-200 text-gray-500'
              }`}
            >
              Commencer
            </button>
          </div>
        </div>
      </div>

      {/* Partie basse scrollable */}
      <div className="flex-1 overflow-y-auto">
        <div className="p-4">
          {/* Sans Matériel */}
          <div className="mb-4">
            <h2 className="text-2xl font-bold mb-2">Sans Matériel</h2>
            <div className="grid grid-cols-3 gap-2">
              {[1, 2, 3].map((stars) => (
                <button
                  key={stars}
                  onClick={() => handleSelection('sans-materiel', stars)}
                  className={`border rounded p-2 w-full ${
                    selectedCategory === 'sans-materiel' && selectedDifficulty === stars
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200'
                  }`}
                >
                  {'⭐'.repeat(stars)}
                </button>
              ))}
            </div>
          </div>

          {/* Avec Matériel */}
          <div className="mb-4">
            <h2 className="text-2xl font-bold mb-2">Avec Matériel</h2>
            <div className="grid grid-cols-3 gap-2">
              {[1, 2, 3].map((stars) => (
                <button
                  key={stars}
                  onClick={() => handleSelection('avec-materiel', stars)}
                  className={`border rounded p-2 w-full ${
                    selectedCategory === 'avec-materiel' && selectedDifficulty === stars
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200'
                  }`}
                >
                  {'⭐'.repeat(stars)}
                </button>
              ))}
            </div>
          </div>

          {/* Rubrique à définir */}
          <div className="mb-4">
            <h2 className="text-2xl font-bold mb-2">Rubrique à définir</h2>
            <div className="grid grid-cols-3 gap-2">
              {[1, 2, 3].map((stars) => (
                <button
                  key={stars}
                  onClick={() => handleSelection('autre', stars)}
                  className={`border rounded p-2 w-full ${
                    selectedCategory === 'autre' && selectedDifficulty === stars
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200'
                  }`}
                >
                  {'⭐'.repeat(stars)}
                </button>
              ))}
            </div>
          </div>

          {/* Nouvelle Rubrique 2 */}
          <div className="mb-4">
            <h2 className="text-2xl font-bold mb-2">Nouvelle Rubrique 2</h2>
            <div className="grid grid-cols-3 gap-2">
              {[1, 2, 3].map((stars) => (
                <button
                  key={stars}
                  onClick={() => handleSelection('nouvelle-rubrique2', stars)}
                  className={`border rounded p-2 w-full ${
                    selectedCategory === 'nouvelle-rubrique2' && selectedDifficulty === stars
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200'
                  }`}
                >
                  {'⭐'.repeat(stars)}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;