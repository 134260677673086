// src/data/exercises.js

export const exerciseData = {
  // Exercices sans matériel
  sansMatériel: {
    haut: [
      { name: "Pompes contre le mur", difficulty: 1, img: "/images/Img1.jpg", video: "/videos/video1.mp4" },
      { name: "Pompes sur tables", difficulty: 1, img: "/images/Img2.jpg", video: "/videos/video2.mp4" },
      { name: "Pompes a genoux sur chaise", difficulty: "1-2", img: "/images/Img3.jpg", video: "/videos/video1.mp4" },
      { name: "A/R planche", difficulty: "1-3", img: "/images/Img3.jpg", video: "/videos/video1.mp4" },
      { name: "Pompes à genoux", difficulty: 2, img: "/images/Img2.jpg", video: "/videos/video1.mp4" },
      { name: "Dips", difficulty: 2, img: "/images/Img2.jpg", video: "/videos/video1.mp4" },
      { name: "Traction sur table", difficulty: 3, img: "/images/Img1.jpg", video: "/videos/video1.mp4" },
      { name: "Pompes", difficulty: 3, img: "/images/Img2.jpg", video: "/videos/video1.mp4" },
      { name: "Pompes avec pieds surélevés", difficulty: 3, img: "/images/Img1.jpg", video: "/videos/video1.mp4" }
    ],
    bas: [
      { name: "Squats", difficulty: "1-3", img: "/images/Img1.jpg", video: "/videos/video1.mp4" },
      { name: "Squat écart / Squat serré / Squat écart", difficulty: "1-3", img: "/images/Img2.jpg", video: "/videos/video2.mp4" },
      { name: "Fentes alternées", difficulty: "1-3", img: "/images/Img3.jpg", video: "/videos/video3.mp4" },
      { name: "Fentes / Squats / Fentes", difficulty: "2-3", img: "/images/Img2.jpg", video: "/videos/video1.mp4" },
      { name: "Fentes marchées", difficulty: "2-3", img: "/images/Img3.jpg", video: "/videos/video1.mp4" },
      { name: "Squats sautés", difficulty: "2-3", img: "/images/Img1.jpg", video: "/videos/video1.mp4" },
      { name: "Fentes sautées", difficulty: 3, img: "/images/Img2.jpg", video: "/videos/video1.mp4" },
      { name: "Squats bulgare", difficulty: 3, img: "/images/Img3.jpg", video: "/videos/video1.mp4" },
      { name: "Squat /pas chassé / squat", difficulty: "1-3", img: "/images/Img1.jpg", video: "/videos/video1.mp4" },
      { name: "Squat montée de genoux alternée", difficulty: "1-3", img: "/images/Img2.jpg", video: "/videos/video1.mp4" },
      { name: "Fentes latérales", difficulty: "1-3", img: "/images/Img1.jpg", video: "/videos/video1.mp4" }
    ],
    abdos: [
      { name: "Gainage ventral sur pieds", difficulty: "2-3", img: "/images/Img1.jpg", video: "/videos/video1.mp4" },
      { name: "Gainage dorsal", difficulty: 3, img: "/images/Img2.jpg", video: "/videos/video2.mp4" },
      { name: "Gainage ventral sur genoux", difficulty: 1, img: "/images/Img3.jpg", video: "/videos/video3.mp4" },
      { name: "Gainage latéral sur pied", difficulty: "2-3", img: "/images/Img2.jpg", video: "/videos/video1.mp4" },
      { name: "Gainage latéral sur genoux", difficulty: 1, img: "/images/Img3.jpg", video: "/videos/video1.mp4" },
      { name: "Mountain climber", difficulty: "1-3", img: "/images/Img1.jpg", video: "/videos/video1.mp4" },
      { name: "Gainage avant bras/mains", difficulty: "1-3", img: "/images/Img2.jpg", video: "/videos/video1.mp4" }
    ]
  },
  // Exercices avec matériel
  avecMatériel: {
    haut: [
      { name: "Haut 1", difficulty: 1, img: "/images/Img2.jpg", video: "/videos/video0.mp4" },
      { name: "Haut 2", difficulty: "1-2", img: "/images/Img3.jpg", video: "/videos/video0.mp4" },
      { name: "Haut 3", difficulty: 2, img: "/images/Img1.jpg", video: "/videos/video0.mp4" }
    ],
    bas: [
      { name: "Bas 1", difficulty: "1-3", img: "/images/Img2.jpg", video: "/videos/video0.mp4" },
      { name: "Bas 2", difficulty: "2-3", img: "/images/Img3.jpg", video: "/videos/video0.mp4" },
      { name: "Bas 3", difficulty: 3, img: "/images/Img1.jpg", video: "/videos/video0.mp4" }
    ],
    abdos: [
      { name: "Abdos 1", difficulty: 1, img: "/images/Img2.jpg", video: "/videos/video0.mp4" },
      { name: "Abdos 2", difficulty: "2-3", img: "/images/Img1.jpg", video: "/videos/video0.mp4" },
      { name: "Abdos 3", difficulty: 3, img: "/images/Img3.jpg", video: "/videos/video0.mp4" }
    ]
  },
  // Exercices Rubrique à définir
  nouvelleRubrique: {
    haut: [
      { name: "Haut 1a", difficulty: 1, img: "/images/Img2.jpg", video: "/videos/video0.mp4" },
      { name: "Haut 2a", difficulty: "1-2", img: "/images/Img3.jpg", video: "/videos/video0.mp4" },
      { name: "Haut 3a", difficulty: 2, img: "/images/Img1.jpg", video: "/videos/video0.mp4" }
    ],
    bas: [
      { name: "Bas 1a", difficulty: "1-3", img: "/images/Img2.jpg", video: "/videos/video0.mp4" },
      { name: "Bas 2a", difficulty: "2-3", img: "/images/Img3.jpg", video: "/videos/video0.mp4" },
      { name: "Bas 3a", difficulty: 3, img: "/images/Img1.jpg", video: "/videos/video0.mp4" }
    ],
    abdos: [
      { name: "Abdos 1a", difficulty: 1, img: "/images/Img2.jpg", video: "/videos/video0.mp4" },
      { name: "Abdos 2a", difficulty: "2-3", img: "/images/Img1.jpg", video: "/videos/video0.mp4" },
      { name: "Abdos 3a", difficulty: 3, img: "/images/Img3.jpg", video: "/videos/video0.mp4" }
    ]
  },
  // Exercices Rubrique à définir
  nouvelleRubrique2: {
    haut: [
      { name: "Haut 1a", difficulty: 1, img: "/images/Img2.jpg", video: "/videos/video0.mp4" },
      { name: "Haut 2a", difficulty: "1-2", img: "/images/Img3.jpg", video: "/videos/video0.mp4" },
      { name: "Haut 3a", difficulty: 2, img: "/images/Img1.jpg", video: "/videos/video0.mp4" }
    ],
    bas: [
      { name: "Bas 1a", difficulty: "1-3", img: "/images/Img2.jpg", video: "/videos/video0.mp4" },
      { name: "Bas 2a", difficulty: "2-3", img: "/images/Img3.jpg", video: "/videos/video0.mp4" },
      { name: "Bas 3a", difficulty: 3, img: "/images/Img1.jpg", video: "/videos/video0.mp4" }
    ],
    abdos: [
      { name: "Abdos 1a", difficulty: 1, img: "/images/Img2.jpg", video: "/videos/video0.mp4" },
      { name: "Abdos 2a", difficulty: "2-3", img: "/images/Img1.jpg", video: "/videos/video0.mp4" },
      { name: "Abdos 3a", difficulty: 3, img: "/images/Img3.jpg", video: "/videos/video0.mp4" }
    ]
  }
};

export const getRandomExercises = (category, difficulty) => {
  // Sélectionner le bon groupe d'exercices selon la catégorie
  let exercises;
  switch(category) {
    case 'sans-materiel':
      exercises = exerciseData.sansMatériel;
      break;
    case 'avec-materiel':
      exercises = exerciseData.avecMatériel;
      break;
    case 'nouvelle-rubrique':
      exercises = exerciseData.nouvelleRubrique;
      break;
    case 'nouvelle-rubrique2':
      exercises = exerciseData.nouvelleRubrique2;
      break;
    default:
      exercises = exerciseData.sansMatériel;
  }
  // Sélectionner un exercice dans chaque zone (haut, bas, abdos)
  const selectedExercises = [];
  const zones = ['haut', 'bas', 'abdos'];

  zones.forEach(zone => {
    const availableExercises = exercises[zone].filter(exercise => {
      if (typeof exercise.difficulty === 'string') {
        const [min, max] = exercise.difficulty.split('-').map(Number);
        return difficulty >= min && difficulty <= max;
      }
      return exercise.difficulty === difficulty;
    });

    if (availableExercises.length > 0) {
      const randomIndex = Math.floor(Math.random() * availableExercises.length);
      const selectedExercise = availableExercises[randomIndex];
      
      selectedExercises.push({
        ...selectedExercise,
        category: zone.charAt(0).toUpperCase() + zone.slice(1),
        // Ajouter la difficulté sélectionnée
        selectedDifficulty: difficulty,
        // Garder l'information de la plage de difficulté possible
        originalDifficulty: selectedExercise.difficulty
      });
    }
  });

  return selectedExercises;
};