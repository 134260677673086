import React, { useEffect, useState } from 'react';

const SplashScreen = ({ onClose }) => {
  const [timer, setTimer] = useState(15);

  useEffect(() => {
    // Timer pour fermer automatiquement
    const countdown = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    // Timer pour fermer le splash screen
    const timeout = setTimeout(() => {
      onClose();
    }, 15000);

    // Nettoyage
    return () => {
      clearInterval(countdown);
      clearTimeout(timeout);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-orange-400 flex flex-col items-center justify-center p-8 z-50">
      <img 
        src="/images/silhouettes_orange.jpg" 
        alt="silhouettes" 
        className="w-32 h-auto mb-8"
      />
      
      <h1 className="text-xl font-bold text-center text-white mb-6">
        Ajoutez-moi à l'écran d'accueil pour une utilisation optimale.
      </h1>

      <button
        onClick={onClose}
        className="px-6 py-3 bg-white rounded-lg text-orange-400 font-semibold hover:bg-opacity-90 mb-4"
      >
        Compris !
      </button>

      <div className="text-white/60 text-sm">
        Fermeture automatique dans {timer}s
      </div>
    </div>
  );
};

export default SplashScreen;