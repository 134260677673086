import React from 'react';
import { ArrowLeft } from 'lucide-react';

const ExerciseChoice = ({ exercises, onStart, onReroll, onBack }) => {
  return (
    <div className="p-4">
      {/* Header avec icône retour et logo centré */}
      <div className="relative flex justify-center items-center mb-6">
        <button 
          onClick={onBack} 
          className="absolute left-0"
        >
          <ArrowLeft size={24} />
        </button>
        <img 
          src="/images/silhouettes.jpg" 
          alt="silhouettes" 
          className="w-20 h-auto"
        />
      </div>

      {/* Liste des exercices */}
      <div className="space-y-3 mb-6">
        {exercises.map((exercise, index) => (
          <div 
            key={index} 
            className="border border-gray-200 rounded-lg p-4 w-full"
          >
            <div className="flex items-start gap-4">
              {/* Image */}
              <img
                src={exercise.img}
                alt={exercise.name}
                className="w-16 h-16 object-cover rounded-lg"
              />
              
              {/* Informations */}
              <div className="flex-1">
                <h3 className="font-medium mb-1">{exercise.name}</h3>
                <div className="text-gray-600 mb-1">{exercise.category}</div>
                
                {/* Affichage de la difficulté sélectionnée uniquement */}
                <div className="flex items-center gap-1">
                  <span className="text-sm text-gray-500">Difficulté :</span>
                  <div className="flex gap-1">
                    {Array(exercise.selectedDifficulty || exercise.difficulty)
                      .fill('⭐')
                      .map((star, i) => (
                        <span key={i} className="text-yellow-400">
                          {star}
                        </span>
                      ))}
                  </div>
                </div>

                {/* Indication optionnelle pour les exercices multi-niveaux */}
                {typeof exercise.difficulty === 'string' && (
                  <div className="text-xs text-gray-500 mt-1 italic">
                    Exercice adaptable en difficulté
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Boutons d'action */}
      <button 
        onClick={onReroll}
        className="w-full bg-red-600 text-white p-4 rounded-lg mb-3"
      >
        Autre choix
      </button>
      <button 
        onClick={onStart}
        className="w-full bg-blue-600 text-white p-4 rounded-lg mb-3"
      >
        Commencer
      </button>

    </div>
  );
};

export default ExerciseChoice;