import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeft, Play, Pause } from 'lucide-react';

const Exercise = ({ exercises, currentExercise, totalExercises, onComplete, onBack }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [phase, setPhase] = useState('prep');
  const [timer, setTimer] = useState(10);
  const videoRef = useRef(null);
  const animationFrameRef = useRef();
  const dongSoundRef = useRef(new Audio('/sounds/dong.mp3'));

  const playDong = () => {
    if (!isPaused) {
      const dong = dongSoundRef.current;
      dong.currentTime = 0;
      dong.play().catch(() => {});
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const updateTimer = () => {
      const currentTime = video.currentTime;
      const newTime = currentTime <= 10 
        ? Math.ceil(10 - currentTime)
        : Math.ceil(40 - currentTime);

      if (currentTime <= 40 && (Math.ceil(40 - currentTime) % 10 === 0) && timer !== newTime) {
        playDong();
      }
      
      if (currentTime <= 10) {
        setPhase('prep');
        setTimer(newTime);
      } 
      else if (currentTime <= 40) {
        setPhase('exercise');
        setTimer(newTime);
      }

      if (!isPaused) {
        animationFrameRef.current = requestAnimationFrame(updateTimer);
      }
    };

    const handleVideoEnd = () => {
      video.pause();
      cancelAnimationFrame(animationFrameRef.current);
      onComplete();
    };

    video.addEventListener('ended', handleVideoEnd);
    
    if (!isPaused) {
      video.play().catch(() => {});
      animationFrameRef.current = requestAnimationFrame(updateTimer);
    } else {
      video.pause();
    }

    return () => {
      video.removeEventListener('ended', handleVideoEnd);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      video.pause();
    };
  }, [isPaused, onComplete, timer]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const initializeVideo = () => {
      setPhase('prep');
      setTimer(10);
      setIsPaused(false);
      video.currentTime = 0;
      video.play().catch(() => {});
      playDong();
    };

    setTimeout(initializeVideo, 100);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [currentExercise]);

  // Simplifié le handleNext
  const handleNext = () => {
    if (currentExercise < totalExercises) {
      onComplete();
    }
  };

  const togglePause = () => {
    setIsPaused(prev => !prev);
  };

  const currentExerciseData = exercises[currentExercise - 1];

  return (
    <div className="min-h-screen bg-black flex flex-col">
      {/* Header */}
      <div className="absolute top-0 left-0 right-0 z-10 p-4">
        <div className="flex justify-between items-center px-4">
          <button 
            onClick={onBack}
            className="text-white hover:opacity-75"
          >
            <ArrowLeft size={24} />
          </button>
          <div className="text-white text-center">
            <div>Exercice {currentExercise}/{totalExercises}</div>
            <div className="text-sm">{currentExerciseData.name}</div>
          </div>
          <div className="w-6" />
        </div>
      </div>

      {/* Zone vidéo cliquable */}
      <div 
        className="flex-1 flex flex-col items-center justify-center cursor-pointer"
        onClick={togglePause}
      >
        <video
          ref={videoRef}
          key={`${currentExerciseData.video}-${currentExercise}`}
          className="w-full h-full object-cover absolute inset-0"
          playsInline
          muted
          preload="auto"
        >
          <source src={currentExerciseData.video} type="video/mp4" />
        </video>

        {/* Timer et bouton pause */}
        <div className="absolute inset-0 flex flex-col items-center justify-center pointer-events-none">
          <div 
            className="text-white/30 text-[150px] mb-8"
            style={{ 
              fontFamily: 'Orbitron, sans-serif',
              letterSpacing: '-0.02em'
            }}
          >
            {String(timer).padStart(2, '0')}
          </div>

          <div className="rounded-full w-16 h-16 flex items-center justify-center">
            {isPaused ? (
              <Play size={40} className="text-white/30" />
            ) : (
              <Pause size={40} className="text-white/30" />
            )}
          </div>
        </div>
      </div>

      {/* Bouton Vidéo suivante */}
      <div className="absolute bottom-0 left-0 right-0 p-4 flex justify-center">
        <button
          onClick={handleNext}
          disabled={currentExercise === totalExercises}
          className={`px-6 py-3 rounded-lg bg-white/10 text-white hover:bg-white/20 transition-colors ${
            currentExercise === totalExercises ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {currentExercise === totalExercises ? 'Dernier exercice' : 'Exercice suivant'}
        </button>
      </div>
    </div>
  );
};

export default Exercise;